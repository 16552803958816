import React, {useState, useEffect} from "react"
//import 'uikit/dist/css/uikit.css'
import "./html-editor.scss"
import QuoteComponent  from "../quote-component/quoteComponent"

const HtmlEditor = ({htmlContent, fullWidth}) => {

    const [windowActive, setWindowActive] = useState(false)

    useEffect(() => {

        if (typeof window !== 'undefined') {
           setWindowActive(true)
        }
        else{
            setWindowActive(false)
        }

    }, [])

    const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 1000);
    },
        [])

    return(
       
        (windowActive )?
        <>
        <div dangerouslySetInnerHTML={{__html: htmlContent}} className={!fullWidth?"html-editor html-editor-boxedWidth":'html-editor'}>
        </div>
        <QuoteComponent src="https://sf.surefiresoftware.co.uk/surequote2/surequote.js"/>
        </>
        :
        <div></div>
    )

}

export default HtmlEditor