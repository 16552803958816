import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CustomMarkdown from "../../utils/customMarkdown";
import './table-component.scss';
import '../../styles/variables.scss';
import TopFillPolygon from "../top-fill-polygon/top-fill-polygon";
import BottomFillPolygon from "../bottom-fill-polygon/bottom-fill-polygon";

const TableComponent = ({ BackgroundColor, PolygonBottom, PolygonTop, PaddingTop, PaddingBottom, TableType }) => {
    var paragraphStyle = {};
    PaddingTop && PaddingBottom ? paragraphStyle = { padding: '50px 15px' }
        : PaddingTop ? paragraphStyle = { paddingTop: '50px', paddingLeft: '15px', paddingRight: '15px' }
            : PaddingBottom ? paragraphStyle = { paddingBottom: '50px', paddingLeft: '15px', paddingRight: '15px' }
                : paragraphStyle = {}

    switch (TableType === "FinanceOption") {
        case true:
            return (
                <>
                    {
                        PolygonTop ? <TopFillPolygon /> : ""
                    }
                    <div className={`table-container ${BackgroundColor}`}>
                        <div className="table-content max-width" style={paragraphStyle}>
                            <table className="uk-table uk-table-striped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="second-table-title">6 Months - 2 Years</th>
                                        <th className="third-table-title">3 Years - 10 Years</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="table-row">Interest Rate:</td>
                                        <td className="table-value">0%*</td>
                                        <td className="table-value">13.9% APR representative</td>
                                    </tr>

                                    <tr>
                                        <td className="table-row">Representative Example:</td>
                                        <td className="table-value">2 Years (24 Months)</td>
                                        <td className="table-value">10 Years (120 Months)</td>
                                    </tr>

                                    <tr>
                                        <td className="table-row">Installation Cost:</td>
                                        <td className="table-value">£2,400</td>
                                        <td className="table-value">£2,400</td>
                                    </tr>

                                    <tr>
                                        <td className="table-row">Cost Per Month:</td>
                                        <td className="table-value">£100</td>
                                        <td className="table-value">£35.95</td>
                                    </tr>

                                    <tr>
                                        <td className="table-row">Total Amount Repayable:</td>
                                        <td className="table-value">£2,400</td>
                                        <td className="table-value">£4,314</td>
                                    </tr>

                                    <tr>
                                        <td className="table-row">Advantage:</td>
                                        <td className="table-value">Spread the cost, pay no more.</td>
                                        <td className="table-value">Low monthly payments.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        PolygonBottom ? <BottomFillPolygon /> : ""

                    }
                </>

            )
        case false:
            return (
                <>
                    {
                        PolygonTop ? <TopFillPolygon /> : ""
                    }
                    <div className={`table-container ${BackgroundColor}`}>
                        <div className="table-content max-width" style={paragraphStyle}>
                        <table className="uk-table uk-table-striped">
                                <thead>
                                    <tr>
                                        <th className="second-table-title">Who we CAN work with:</th>
                                        <th className="third-table-title">Who we CAN’T work with:</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="table-value">Minimum age of 18 years</td>
                                        <td className="table-value">Unemployed without a spouse/partner in full time employment</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">UK resident for more than 12 months</td>
                                        <td className="table-value">Temporary positions/agency workers</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Own personal bank account from which a Direct Debit can be taken</td>
                                        <td className="table-value">Foster carers without a spouse/partner who meets the minimum employment criteria</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Owner occupier</td>
                                        <td className="table-value">Child-minders who do not have registered creche/ nursery</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Permanent employment, working minimum 16 hours per week</td>
                                        <td className="table-value">Full time students (other than trainee nurses and doctors)</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Self employed</td>
                                        <td className="table-value">Supply teachers who do not have a permanent contract with a specific school</td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Retired person</td>
                                        <td className="table-value"></td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">On disability allowance</td>
                                        <td className="table-value"></td>
                                    </tr>

                                    <tr>
                                        <td className="table-value">Unemployed and ‘house person’ (with consent of co- habiting working partner)</td>
                                        <td className="table-value"></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        PolygonBottom ? <BottomFillPolygon /> : ""

                    }
                </>


            )
        default:
            return <p>Nothing to return</p>
    }


}

export default TableComponent;
