import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'

import { Helmet } from "react-helmet"

import Header from "../components/header/header"
import Enquire from "../components/enquire/enquire"
import CardSlider from "../components/card-slider/card-slider"
import Paragraph from "../components/paragraph/paragraph"
import CTAList from "../components/cta-list/cta-list"
import QuestionList from "../components/question-list/question-list"
import Timeline from "../components/timeline/timeline"
import Accreditations from "../components/accreditations/accreditations"
import ImageComponent from "../components/image-component/image-component"
import PriceCard from "../components/price-card/price-card"
import TableComponent from "../components/table-component/table-component"
import HtmlEditor from "../components/html-editor/html-editor"


export const query = graphql`
  query primaryPagesQuery($id: Int) {
    strapiPages(strapiId: { eq: $id }) {
    Seo {
      id
      metaTitle
      metaDescription
    }
    PageContent
    }
    strapiGlobal {
        Discount
      }
    allStrapiImageCards {
    edges {
      node {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED, 
                formats: [AUTO, WEBP]
                )
            }
          }
        }
      }
    }
  } 
    allFile {
        edges {
          node {
            publicURL
            id
            name
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
  }
`;






// markup
const PrimaryPage = ({ data }) => {

    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        //   console.log(imgUrl)
        return (imgUrl)
    }



    if (data.strapiPages.PageContent) {

        return (

            <Layout SEOTitle={data.strapiPages.Seo.metaTitle || data.strapiPages.Title} SEODescription={data.strapiPages.Seo.metaDescription} footerDisclaimer={data.strapiPages.FooterDisclaimer}>

                {/** List of meta properties coming from strapi that applies only for the primary pages */
                    <Helmet>
                        {data.strapiPages.MetaTags?.map((item, index) => {
                            return (
                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            )
                        })}
                    </Helmet>
                }


                {data.strapiPages.PageContent.map((item, index) => {


                    switch (item.strapi_component) {



                        case "page-components.header":
                            return (
                                <>
                                    {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                    <Header key={index}
                                        Title={item.Title}
                                        Subtitle={item.Subtitle}
                                        FirstCTALabel={item.FirstCTALabel}
                                        FirstCTAURL={item.FirstCTAURL}
                                        Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                        Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                        BackgroundColor={item.BackgroundColorHeader}
                                    ></Header>
                                </>

                            )
                        case "page-components.our-services":
                            let cards = [];
                            item.ServiceCard.map((item, index) => {
                                let Image = getImgUrl(item.Image.localFile___NODE);
                                cards.push({
                                    Title: item.Title,
                                    Subtitle: item.Subtitle,
                                    CTALabel: item.CTALabel,
                                    CTAUrl: item.CTAUrl,
                                    TextColor: item.TextColor,
                                    Image: Image
                                })
                            })
                            return (
                                <CTAList
                                    key={index}
                                    Type="our-service"
                                    Cards={cards}
                                    Content={item.Content}
                                    FooterText={item.FooterText}
                                    BackgroundColor={item.BackgroundColor}
                                    PolygonBottom={item.PolygonBottom}
                                    PolygonTop={item.PolygonTop}
                                />
                            )
                        case "page-components.paragraph":
                            return (
                                <Paragraph
                                    key={index}
                                    Content={item.Content}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    ImagePosition={item.ImagePosition}
                                    BackgroundColor={item.BackgroundColor}
                                    PolygonBottom={item.PolygonBottom}
                                    PolygonTop={item.PolygonTop}
                                    PaddingTop={item.PaddingTop}
                                    PaddingBottom={item.PaddingBottom}
                                    Background={item.Background !== null ? getImgUrl(item.Background[0]?.localFile___NODE) : ""}
                                    GradientColor={item.GradientColor}

                                />
                            )

                        case "page-components.card-slider":
                            let sliderCards = [];
                            item.Card.map((item, index) => {
                                let Image = getImgUrl(item.Image.localFile___NODE);
                                sliderCards.push({
                                    Title: item.Title,
                                    Subtitle: item.Subtitle,
                                    Image: Image
                                })

                            })
                            return (
                                <CardSlider key={index}
                                    Content={item.Content}
                                    Cards={sliderCards} />
                            )

                        case "page-components.enquire":
                            return (
                                <Enquire
                                    key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FooterText={item.FooterText}
                                    CTALabel={item.CTALabel}
                                    CTAUrl={item.CTAUrl}
                                    Background={item.Background !== null ? getImgUrl(item.Background[0]?.localFile___NODE) : ""}
                                />
                            )
                        case "page-components.fa-qs":
                            return (
                                <QuestionList key={index}
                                    Title={item.Title}
                                    Question={item.Question} />
                            )

                        case "page-components.timeline":
                            let timelineCards = [];
                            item.Card.map((item, index) => {
                                let Image = getImgUrl(item.Image.localFile___NODE);
                                timelineCards.push({
                                    Title: item.Title,
                                    Subtitle: item.Subtitle,
                                    Image: Image
                                })
                            })

                            return (
                                <Timeline key={index}
                                    Content={item.Content}
                                    Cards={timelineCards} />
                            )

                        case "page-components.slider-image":
                            let images = [];
                            item.image.map((item, index) => {

                                let imageURL = getImgUrl(item.Picture.localFile___NODE);
                                images.push({
                                    Alt: item.Alt,
                                    Image: imageURL
                                });
                            })
                            return (
                                <Accreditations
                                    key={index}
                                    Images={images}
                                    Description={item.Description}
                                />
                            )

                        case "page-components.image-component":
                            let imgCards = [];
                            item.image_cards.map((item, index) => {
                                let Image = getImgUrl(item.Image.localFile___NODE);
                                imgCards.push({
                                    Image: Image,
                                    //Alt: item.Alt
                                })
                            })

                            return (
                                <ImageComponent
                                    key={index}
                                    Images={imgCards}
                                />
                            )

                        case "page-components.price-component":
                            let serviceCards = [];
                            item.price_services.map((item, index) => {
                                let Image = getImgUrl(item.Image.localFile___NODE);
                                serviceCards.push({
                                    Image: Image,
                                    Title: item.Title,
                                    Description: item.Description,
                                    Price: item.Price
                                })
                            })

                            return (
                                <PriceCard
                                    key={index}
                                    Type="price-component"
                                    Discount={data.strapiGlobal.Discount}
                                    Services={serviceCards}
                                    BackgroundColor={item.BackgroundColor}
                                    PolygonBottom={item.PolygonBottom}
                                    PolygonTop={item.PolygonTop}
                                />
                            )
                        case "page-components.table-component":
                            return (
                                <TableComponent
                                    key={index}
                                    BackgroundColor={item.BackgroundColor}
                                    PolygonTop={item.PolygonTop}
                                    PolygonBottom={item.PolygonBottom}
                                    PaddingTop={item.PaddingTop}
                                    PaddingBottom={item.PaddingBottom}
                                    TableType={item.TableType}

                                />
                            )



                        case "page-components.html-editor":
                            return (

                                <HtmlEditor
                                    key={index}
                                    htmlContent={item.HTML}
                                    fullWidth={item.FullWidth}
                                ></HtmlEditor>

                                

                            )

                        default:
                            return ("")
                    }



                })}


            </Layout>
        )

    } else { return (<Layout>page empty. add some components.</Layout>) }

}

export default PrimaryPage