
// CustomScriptComponent.js
import React, { useEffect } from 'react';

const QuoteComponent = ({ src }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [src]);

  return <div></div>;
};

export default QuoteComponent;